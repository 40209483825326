/**
 * When the theme has multiple languages show the language picker and bind click events.
 *
 * First check if the picker exists. This is automaticly added if more than 1 language is active.
 */
function languagePicker(): void {
  const body = document.querySelector("body");
  if (body.querySelector(".language-picker")) {
    const languagePickers = body.querySelectorAll(".language-picker");
    languagePickers.forEach((picker) => {
      const langToggle = picker.querySelector(".current-language-button");
      langToggle.addEventListener("click", () => {
        picker.classList.toggle("active");
      });
    });
  }
}
export { languagePicker };
