/* FORM */
export const fileSeperator = ",";
// Validators
export const emailRe =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRe =
  /^[(\+|00)]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

// Classes used for mutationObserver
export const mutationObservedClasses = [
  "plate--element",
  "plate--row",
  "plate--column",
  "plate--section",
];

/* BREAKPOINTS */
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
  xxxxl: 1800,
  hd: 1920,
};
